<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcCandidates }}</h1>
    </header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <!-- / Page Header -->
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcTemporarilyChangeCamps"
        :i18n="translations.components"></camp-selector>
      <!-- / Camp Selector -->
      <data-table :fields="fields" :items="translatedProspects" :csvUrl="prospectListReportAsCSVUrl"
        :pdfUrl="prospectListReportAsPDFUrl" :reportName="reportName" :campType="campType"
        :noRecordsMessage="translations.tcNoProspectsFound" individualType="prospect" :addItems="addItems"
        @addProspect="addProspect" :i18n="translations.components"></data-table>
      <!-- / Data Tables -->
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMembers.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prospects',
  mixins: [translationMixin],
  data() {
    return {
      addItems: {},
      fields: [],
      genderOptions: [{ letter: 'G', cast: 'Gideon' }, { letter: 'A', cast: 'Auxiliary' }, { letter: 'U', cast: 'Unspecified' }],
      reportName: 'ProspectsListByOrganization',
      secured_profile_add_controls: {
        add_candidate_profile_button: '90fdaab9-7bb8-49ed-a6c3-17f51a359676',
      },
      translations: {},
      translatedProspects: [],
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearIndividualProfile: 'membership/clearIndividualProfile',
      clearMemberDefaultType: 'membership/clearMemberTypeDefault',
      clearProspectProfile: 'membership/clearProspectProfile',
      loadCampType: 'user/loadSelectedCampType',
      loadProspectListReportUrls: 'membership/loadProspectListReportUrls',
      loadProspects: 'membership/loadProspects',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberDefaultType: 'membership/loadMemberTypeDefault',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        this.addItems.display = this.iCanSee(this.secured_profile_add_controls.add_candidate_profile_button)
        await Promise.all([
          this.clearMemberDefaultType(),
          this.setMemberDefaultType('abda920a-f939-4de0-bad5-0ab91f476b5e'),
          this.getViewTranslations(),
          this.getComponentTranslations('data-table-members', 'security-banner', 'camp-select'),
          this.loadProspects(),
          this.loadProspectListReportUrls(),
          this.loadCampType(),
        ]).then((results) => {
          this.setMemberDefaultType('abda920a-f939-4de0-bad5-0ab91f476b5e'),
            (this.translations.components = results[3]),
            this.setLoadingStatus(false)
          this.loadTranslations()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    addProspect() {
      this.clearIndividualProfile()
      this.clearProspectProfile()
      this.$router.push({ name: 'add-prospect' })
    },
    loadTranslations() {
      if (!this.translations || !this.prospects) return false
      this.translatedProspects = this.prospects.map((prospect) => {
        // Translate Gideon/Auxiliary member types
        const prospectType = `tc${prospect.type}`
        const typeText = this.translations.hasOwnProperty(prospectType)
          ? this.translations[prospectType]
          : prospect.type
        return { ...prospect, ind_key: prospect.prs_ind_key, type: typeText }
      })
      this.fields = [
        { key: 'sort_name', label: this.translations.tcName, sortable: true },
        { key: 'add_date', label: this.translations.tcAddDate, sortable: true },
        { key: 'gender', label: this.translations.tcType, sortable: true },
        { key: 'email', label: this.translations.tcEmail, sortable: true }
      ]
      this.addItems = {
        display: true,
        text: this.translations.tcAddCandidate,
        action: 'addProspect',
      }
    },
  },
  computed: {
    ...mapGetters({
      campType: 'user/userSelectedCampType',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      prospects: 'membership/prospects',
      prospectListReportAsCSVUrl: 'membership/prospectListReportAsCSVUrl',
      prospectListReportAsPDFUrl: 'membership/prospectListReportAsPDFUrl',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
